import React, { useState, useEffect, FC } from 'react';
import { Button, Col, Row } from 'antd';
import classnames from 'classnames';
import Countdown, { zeroPad } from 'react-countdown';
import { getContract } from 'hook';
import { useWeb3React } from '@web3-react/core';
import { uniq } from 'lodash';
import BigNumber from 'bignumber.js';
import StatisticComponent from 'components/Statistic';
import { convertEToNumber, formatUnits, toNumber } from 'utils/helpers';
import { TOKEN_ADDRESS, TYPE_OF_ANT_DESIGN } from 'common/constant';
import showMessage from 'components/Message';
import TokenJSON from 'contracts/abiToken.json';

import Icon1 from 'resources/images/icon-statistic-1.png';
import Icon2 from 'resources/images/icon-statistic-2.png';
import Icon3 from 'resources/images/icon-statistic-3.png';
import Icon4 from 'resources/images/icon-statistic-4.png';
import Icon1White from 'resources/images/icon-1-white.svg';
import Icon2White from 'resources/images/icon-2-white.svg';
import Icon3White from 'resources/images/icon-3-white.svg';
import Icon4White from 'resources/images/icon-4-white.svg';
import Icon5White from 'resources/images/icon-5-white.svg';

BigNumber.config({
  EXPONENTIAL_AT: 100,
});

const convertHexToDecimal = (value: any) => {
  if (!value) {
    return '0';
  }

  const newValue = new BigNumber(value.toString()).dividedBy(Math.pow(10, 18)).toString();

  return newValue;
};

const sumTwoNumber = (a: number | string | null, b: number | string | null) => {
  return new BigNumber(a || 0).plus(b || 0).toString();
};

const minusTwoNumber = (a: number | string | null, b: number | string | null) => {
  return new BigNumber(a || 0).minus(b || 0).toString();
};

const SummaryRoundComponent: FC<{
  account: null | undefined | string;
  data: any;
}> = ({ account, data }) => {
  const [totalValue, setTotalValue] = useState({}) as any;

  useEffect(() => {
    const newTotalValue = Object.values(data).reduce(
      (acc: any, cur: any) => ({
        allocationAmount: sumTwoNumber(acc.allocationAmount, cur.allocationAmount),
        claimedAmount: sumTwoNumber(acc.claimedAmount, cur.claimedAmount),
        availableAmount: sumTwoNumber(acc.availableAmount, cur.availableAmount),
        claimableAmount: sumTwoNumber(acc.claimableAmount, cur.claimableAmount),
      }),
      {
        allocationAmount: 0,
        claimedAmount: 0,
        availableAmount: 0,
        claimableAmount: 0,
      }
    );
    setTotalValue(newTotalValue);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, data]);

  return (
    <Row
      gutter={[
        0,
        {
          xs: 44,
          sm: 44,
          lg: 74,
        },
      ]}
    >
      <Col span={24}>
        <h4 className="title">TOREKKO VESTING PORTAL</h4>
        <h5 className="description">FIRST JAPANIMATION METAVERSE</h5>
      </Col>
      <Col span={24}>
        <Row gutter={[30, 16]}>
          <Col xs={24} lg={12} xl={6}>
            <StatisticComponent
              percent={totalValue.allocationAmount}
              isConnected={!!account}
              title="Allocation Amount"
              iconUrl={Icon1}
              className="statistic"
            />
          </Col>
          <Col xs={24} lg={12} xl={6}>
            <StatisticComponent
              percent={totalValue.availableAmount}
              isConnected={!!account}
              title="Available Amount"
              iconUrl={Icon2}
              className="statistic"
            />
          </Col>
          <Col xs={24} lg={12} xl={6}>
            <StatisticComponent
              percent={totalValue.claimedAmount}
              isConnected={!!account}
              title="Claimed Amount"
              iconUrl={Icon3}
              className="statistic"
            />
          </Col>

          <Col xs={24} lg={12} xl={6}>
            <StatisticComponent
              percent={totalValue.claimableAmount}
              isConnected={!!account}
              title="Claimable Amount"
              iconUrl={Icon4}
              className="statistic"
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

const RoundComponent: FC<{
  updateAllData: any;
  title?: string;
  isCountdown?: null | undefined | number;
  address: string;
  abi?: any;
  communityRound?: boolean;
  index?: number;
  setListRoundVisible?: any;
  keyItem?: number;
}> = ({
  updateAllData,
  title = '',
  isCountdown = false,
  address,
  abi,
  communityRound,
  index,
  setListRoundVisible,
  keyItem,
}) => {
  const { account, library } = useWeb3React();

  const [roundData, setRoundData] = useState({
    allocationAmount: null,
    claimedAmount: null,
    claimableAmount: null,
    availableAmount: null,
  });
  const [isShowBtnClaim, setIsShowBtnClaim] = useState(false);

  const [timeCountdown, setTimeCountdown] = useState(0);
  const [isBtnClaim, setBtnClaim] = useState(false);

  const rendererCountdown = ({ days, hours, minutes, seconds }: any) => {
    return (
      <ul className="countdown">
        <li className="time">
          <span className={classnames('value', { highlight: days })}>{zeroPad(days)}D</span>
        </li>
        :
        <li className="time">
          <span className={classnames('value', { highlight: days || hours })}>{zeroPad(hours)}H</span>
        </li>
        :
        <li className="time">
          <span
            className={classnames('value', {
              highlight: days || hours || minutes,
            })}
          >
            {zeroPad(minutes)}M
          </span>
        </li>
        :
        <li className="time">
          <span
            className={classnames('value', {
              highlight: days || hours || minutes || seconds,
            })}
          >
            {zeroPad(seconds)}S
          </span>
        </li>
      </ul>
    );
  };

  /**
   * get Balance
   * @returns balance
   */
  const getBalance = async (library: any, address: string, tokenAddress: string) => {
    const tokenInst = getContract(tokenAddress, TokenJSON.output.abi, library);
    if (address) {
      const balance = await tokenInst.balanceOf(address);
      const decimals = await tokenInst.decimals();

      return {
        balance: convertEToNumber(formatUnits(balance, 'wei'), decimals),
      };
    } else {
      return {
        balance: 0,
      };
    }
  };

  const claimRound = async () => {
    setIsShowBtnClaim(true);

    const { balance } = await getBalance(library, address, TOKEN_ADDRESS);

    if (balance < (roundData?.claimableAmount || 0)) {
      showMessage(
        TYPE_OF_ANT_DESIGN.ERROR,
        'The claiming contract runs out of TRK, please contact the TOREKKO administrators.'
      );

      setIsShowBtnClaim(false);
      return;
    }

    try {
      const contract = getContract(address, abi, library, account as string);
      const res = await contract.unlock(account);
      const receipt = await library.waitForTransaction(res.hash);
      if (receipt.status) {
        getAllData();
      }
      setIsShowBtnClaim(false);
    } catch (err) {
      setIsShowBtnClaim(false);
    }
  };

  const handleComplete = () => {
    setTimeCountdown(0);
    setBtnClaim(true);
  };

  const getAllData = async () => {
    try {
      const contract = getContract(address, abi, library, account as string);
      const lockedAmount = await contract.lockedAmountOf(account);
      const data = await Promise.all([contract.lockedAmountOf(account), contract.releasedAmountOf(account)]);
      let claimableAmount = 0;
      try {
        claimableAmount = (await lockedAmount.gt(0)) ? await contract.canUnlockAmountOf(account) : 0;
      } catch (ex) {
        claimableAmount = 0;
      }
      const [allocationAmount, claimedAmount] = data;
      const availableAmount = minusTwoNumber(convertHexToDecimal(allocationAmount), convertHexToDecimal(claimedAmount));

      const newData = {
        allocationAmount: convertHexToDecimal(allocationAmount),
        claimedAmount: convertHexToDecimal(claimedAmount),
        claimableAmount: convertHexToDecimal(claimableAmount),
        availableAmount: availableAmount,
      };

      if (!toNumber(newData.allocationAmount)) {
        setListRoundVisible((prevState: any) => prevState.filter((item: any) => item !== keyItem));
      } else {
        setListRoundVisible((prevState: any) => uniq([...prevState, keyItem]));
      }

      setRoundData((prevState: any) => ({
        ...prevState,
        ...newData,
      }));

      updateAllData({ ...newData, index });
    } catch (err) {
      console.log('err=', err);
    }
  };

  useEffect(() => {
    if (account) {
      getAllData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, abi, address, index, library]);

  useEffect(() => {
 
    console.log('1111=', isCountdown)
    if (account && isCountdown) {
      const contract = getContract(address, abi, library, account as string);
      const getCountdownData = async () => {
        try {
          let time;
          const currentTime = Math.floor(new Date().getTime() / 1000);
  
      
          if (communityRound) {
            time = await contract.nextUnlockTime();
            setTimeCountdown(time.toNumber() * 1000);
          } else {
            time = await contract.nextUnlockTime();
            setTimeCountdown(time.toNumber() * 1000);
          }
          // if (currentTime >= time.toNumber()) {
          //   setBtnClaim(true);
          // } else {
          //   setBtnClaim(false);
          // }
        } catch (err) {
          console.log(err);
        }
      };
      getCountdownData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, isCountdown, address, abi, library]);

  return (
    <div className={`round ${!!timeCountdown && !isBtnClaim && 'current-coutdown'}`}>
      <h4 className="round__title">
        <div className="text">{title}</div>
      </h4>
      <Row gutter={[{ xs: 45, md: 48 }, 20]}>
        <Col xs={24} md={12}>
          <StatisticComponent
            isConnected={!!account}
            title="Allocation Amount"
            iconUrl={Icon1White}
            percent={roundData.allocationAmount}
            className="statistic-card"
          />
        </Col>
        <Col xs={24} md={12}>
          <StatisticComponent
            isConnected={!!account}
            title="Available Amount"
            iconUrl={Icon2White}
            percent={roundData.availableAmount}
            className="statistic-card"
          />
        </Col>
        <Col xs={24} md={12}>
          <StatisticComponent
            isConnected={!!account}
            title="Claimed Amount"
            iconUrl={Icon3White}
            percent={roundData.claimedAmount}
            className="statistic-card"
          />
        </Col>
        <Col xs={24} md={12}>
          <StatisticComponent
            isConnected={!!account}
            title="Claimable Amount"
            iconUrl={Icon4White}
            percent={roundData.claimableAmount}
            className="statistic-card"
          />
        </Col>
        {timeCountdown && (
          <Col xs={24} md={12}>
            <StatisticComponent
              isConnected={!!account}
              title="Time To Next Unlock"
              iconUrl={Icon5White}
              className="statistic-card"
              children={<Countdown date={timeCountdown} renderer={rendererCountdown} onComplete={handleComplete} />}
            />
          </Col>
        )}
      </Row>
      <div className="round__bottom">
        <Button
          loading={isShowBtnClaim}
          type="primary"
          onClick={claimRound}
          className="button-claim"
          disabled={!toNumber(roundData.claimableAmount) || !account}
        >
          Claim
        </Button>
      </div>
    </div>
  );
};

export { SummaryRoundComponent, RoundComponent };
