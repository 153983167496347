import IconTwitter from 'resources/images/icon-twitter.png';
import IconTelegram from 'resources/images/icon-telegram.png';
import IconMedium from 'resources/images/icon-medium.png';
import IconDiscord from 'resources/images/icon-discord.png';
import IconInstagram from 'resources/images/icon-instagram.png';
import IconLinkedin from 'resources/images/icon-linkedin.png';

const socialLinks = [
  {
    name: 'Instagram',
    icon: IconInstagram,
    link: 'https://www.instagram.com/torekkocom',
  },
  {
    name: 'Twitter',
    icon: IconTwitter,
    link: 'https://twitter.com/torekkocom?s=20',
  },
  {
    name: 'Telegram',
    icon: IconTelegram,
    link: 'https://t.me/torekko',
  },
  {
    name: 'LinkedIn',
    icon: IconLinkedin,
    link: 'https://www.linkedin.com/company/torekko',
  },
  {
    name: 'Discord',
    icon: IconDiscord,
    link: 'https://discord.com/invite/Tnwtu5YjDA',
  },
  {
    name: 'Medium',
    icon: IconMedium,
    link: 'https://medium.com/@torekko',
  },
];

export { socialLinks };
