import TokenLocker2 from 'contracts/abiTokenLocker2.json';

const list = [
  {
    key: 1,
    address: '0x75f3281476Ca884147378894c0D5D1c158a80fd1',
    title: 'Seed Round',
    abi: TokenLocker2.output.abi,
    isCountdown: true,
  },
  {
    key: 2,
    address: '0x1Fa955D4Ff85C905886209dc7cEEC14b5B589A62',
    title: 'Private Round',
    abi: TokenLocker2.output.abi,
    isCountdown: true,
  },
  {
    key: 3,
    address: '0x902e11098A5b45934b6F115043D1602a9199040C',
    title: 'Strategic Round',
    abi: TokenLocker2.output.abi,
    isCountdown: true,
  },
  {
    key: 4,
    address: '0xC00D5e2A7b1cF5c25069dd6EaE17A4eCDea005fB',
    title: ' KOL Round',
    abi: TokenLocker2.output.abi,
    isCountdown: true,
  },
];

export default list;
