import React from 'react';

function FooterComponent() {
  return (
    <div className="footer">
      <div className="container">
        <span className="copyright">Copyright @2021 Torekko Vesting Portal</span>
      </div>
    </div>
  );
}

export default FooterComponent;
